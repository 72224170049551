import navScroll from './navScroll';
import {getElementList} from 'SCRIPT_UTILS/getElementList';

class MegaMenu {
  // selector types
  containerSelector = '';
  mainSelector = '';
  dropSelector = '';
  sidebarSelector = '';
  sidebarLinkSelector = '';
  // contentSelector = '';
  hamburgerSelector = '';
  tabListSelector = '';
  mobileDrawerHdgSelector = '';
  utilityNavSelector = '';
  flyoutWrapSelector = '';
  // stored elements
  copyrightElement: HTMLElement | null = null;
  mainNavTabList: NodeListOf<Element> | null = null;
  megaMenuWrapElement: HTMLElement | null = null;
  dropdownElementList: NodeListOf<HTMLElement> | null = null;
  // state identifiers
  mainActive = '';
  tabFocusedOn = '';
  isPermaOpen = false;
  // static properties
  stateClass = {
    show: 'jsa-is-visible',
    hide: 'jsa-is-hidden',
    mainActive: 'jsa-is-active',
    mobileActive: 'jsa-is-mobile-active',
    mobileOpen: 'jsa-is-mobile-open',
    mobileInactive: 'jsa-is-mobile-inactive',
  };

  constructor(wrapSelector = '.js-nav') {
    this.containerSelector = wrapSelector;
    this.mainSelector = wrapSelector + '__main';
    this.dropSelector = wrapSelector + '__drop';
    this.sidebarSelector = wrapSelector + '__sidebar';
    this.sidebarLinkSelector = wrapSelector + '__sidebar-link';
    // this.contentSelector = wrapSelector + '__content';
    this.hamburgerSelector = wrapSelector + '__hamburger-menu';
    this.tabListSelector = wrapSelector + '__tab-list';
    this.mobileDrawerHdgSelector = wrapSelector + '__mobile-drawer-hdg';
    this.utilityNavSelector = wrapSelector + '__utility';
    this.flyoutWrapSelector = wrapSelector + '__flyout';

    this.init();
  }

  //
  // INITIALIZATION
  // ------------------------------

  init(): void {
    const hasElements = this.setupElements();

    if (!hasElements) {
      return;
    }

    this.setupEventHandlers();
  }

  setupElements(): boolean {
    this.megaMenuWrapElement = document.querySelector(this.containerSelector);
    this.copyrightElement = document.querySelector<HTMLElement>('.cmp-nav__tab-item--copyright');

    if (this.megaMenuWrapElement === null) {
      return false;
    }

    navScroll(this.megaMenuWrapElement);

    this.dropdownElementList = this.megaMenuWrapElement.querySelectorAll<HTMLElement>(
      this.dropSelector
    );
    this.mainNavTabList = this.megaMenuWrapElement.querySelectorAll(
      this.mainSelector
    );

    if (this.dropdownElementList !== null) {
      const flyoutElementList = getElementList<HTMLElement>(
        this.flyoutWrapSelector,
        this.megaMenuWrapElement
      );
      this.renderDropdownHeight(flyoutElementList, this.dropdownElementList);
    }

    // return true if the elements needed are all available
    return true;
  }

  setupEventHandlers(): void {
    // exit early if nav element is not available
    if (this.megaMenuWrapElement === null) {
      return;
    }

    this.megaMenuWrapElement
      .querySelectorAll(this.sidebarSelector)
      .forEach((domElement: Element) => {
        domElement.addEventListener('click', this.clickSidebarHandler);
      });

    this.mainNavTabList?.forEach((mainNavElement: Element) => {
      mainNavElement.addEventListener('click', this.clickMainHandler);
    });

    // Mobile Listeners
    this.megaMenuWrapElement
      .querySelectorAll(this.hamburgerSelector)
      .forEach((domElement: Element) => {
        domElement.addEventListener('click', this.clickHamburgerHandler);
      });

    this.megaMenuWrapElement
      .querySelectorAll(this.mobileDrawerHdgSelector)
      .forEach((drawerHdgElem: Element): void => {
        drawerHdgElem.addEventListener(
          'click',
          this.clickMobileDrawerHdgHandler
        );
      });
  }

  // TEMPORARY EVENT LISTENERS

  setupOutsideCloseListener(): void {
    document.body.addEventListener('click', this.clickOutsideToCloseHandler);
  }

  tearDownOutsideCloseListener(): void {
    document.body.removeEventListener('click', this.clickOutsideToCloseHandler);
  }

  //
  // EVENT HANDLERS
  // ------------------------------

  clickOutsideToCloseHandler = (event: Event): void => {
    const interactedElement = event.target as HTMLElement;

    const menuWrapperElement = interactedElement.closest(
      this.containerSelector
    );

    if (menuWrapperElement === null) {
      this.closeDropdown();
    }
  };

  clickSidebarHandler = (event: Event): void => {
    const elemData = (event.target as HTMLElement).dataset;

    if (elemData.text !== undefined) {
      this.toggleFlyoutContent(elemData.text);
    }
  };

  clickMainHandler = (event: Event): void => {
    const elemData = (event.currentTarget as HTMLElement).dataset;

    // create listener for clickOutsideToCloseHandler
    this.setupOutsideCloseListener();

    this.isPermaOpen = true;

    if (elemData.main !== undefined) {
      this.openDropdown(elemData.main);
    }

    this.mobileShowMainTabSubDrawer(event.currentTarget as HTMLElement);
  };

  // Mobile Event Handlers
  clickHamburgerHandler = (): void => {
    this.toggleMobileNav();
  };

  clickMobileDrawerHdgHandler = (event: Event): void => {
    const elemCurrTarget = event.currentTarget as HTMLElement;
    const elemHref = elemCurrTarget.getAttribute('href');

    // exiting early if the element is a link
    if (elemHref && elemHref !== '#') {
      return;
    }

    if (elemCurrTarget !== null) {
      this.toggleMobileNavDrawers(elemCurrTarget as HTMLElement);
    }
  };

  //
  // DOM MANIPULATION
  // ------------------------------

  /**
   * Looks through all of the elements provided and finds the maximum height. Returning
   * a number value for the max height
   * @param elementList
   * @returns number
   */
  calcMaxElementHeight(elementList: HTMLElement[]): number {
    let maxHeight = 0;

    elementList.forEach((element) => {
      maxHeight =
        element.offsetHeight > maxHeight ? element.offsetHeight : maxHeight;
    });

    return maxHeight;
  }

  renderDropdownHeight(
    dropdownContentList: HTMLElement[],
    dropdownContainerElems: NodeListOf<HTMLElement>
  ): void {
    const maxContentHeight = this.calcMaxElementHeight(dropdownContentList);

    dropdownContainerElems.forEach((dropContainer) => {
      dropContainer.style.height = `${maxContentHeight}px`;
    });
  }

  toggleFlyoutContent(textIdent = ''): void {
    const allContent = this.megaMenuWrapElement?.querySelectorAll(
      this.flyoutWrapSelector
    );

    if (allContent === undefined) {
      return;
    }

    allContent.forEach((item): void => {
      const content = (item as HTMLElement).dataset.content;

      if (content === textIdent) {
        // isShown == False will trigger toggle to flip content to visible
        const hasVisible = item.classList.contains(this.stateClass.show);
        this.toggleShowElement(item, hasVisible);
      } else {
        // hide other flyout content
        this.toggleShowElement(item, true);
      }
    });
  }

  openDropdown(tabIdent: string): void {
    if (tabIdent === this.mainActive) {
      this.closeDropdown();
      return;
    }

    this.changeActiveTab(tabIdent);

    if (this.dropdownElementList !== null) {
      this.dropdownElementList.forEach((dropdownElement) => {
        const matchTabData = dropdownElement.dataset.parentTab;

        this.toggleShowElement(dropdownElement, !(matchTabData !== undefined && matchTabData === tabIdent));
      });
    }
  }

  closeDropdown(): void {
    this.changeActiveTab('');
    this.isPermaOpen = false;

    // remove dropdown menu event listeners
    this.tearDownOutsideCloseListener();

    this.toggleFlyoutContent();

    if (this.dropdownElementList !== null) {
      this.dropdownElementList.forEach((dropdownElement) => {
        this.toggleShowElement(dropdownElement, true);
      });
    }
  }

  toggleShowElement(domElement: Element, isShown: boolean): void {
    if (isShown) {
      domElement.classList.remove(this.stateClass.show);
      domElement.classList.add(this.stateClass.hide);
    } else {
      domElement.classList.remove(this.stateClass.hide);
      domElement.classList.add(this.stateClass.show);
    }
  }

  changeActiveTab(ident: string): void {
    this.mainActive = ident;

    this.mainNavTabList?.forEach((item) => {
      const mainTabIdent = (item as HTMLElement).dataset?.main;

      if (!mainTabIdent) {
        return;
      }

      if (mainTabIdent === this.mainActive) {
        item.classList.add('jsa-is-active');
      } else {
        item.classList.remove('jsa-is-active');
      }
    });
  }

  // Mobile DOM Manipulation
  toggleMobileNav(): void {
    const tabList = document.querySelector<HTMLElement>(this.tabListSelector);

    if (tabList) {
      tabList.classList.toggle(this.stateClass.mobileOpen);
    }
  }

  toggleCopyrightEl(hideEl: boolean): void {
    if(this.copyrightElement === null){
      return
    }

    this.copyrightElement.style.opacity = hideEl ? "0" : "1";
  }
  /**
   * Toggling classes for sublinks of the main nav tabs to open and close sublinks.
   *
   * @param drawerHdg
   */
  toggleMobileNavDrawers(drawerHdg: HTMLElement): void {
    const parentUl = drawerHdg.closest<HTMLUListElement>('ul');

    // toggle state for drawer heading
    drawerHdg.classList.toggle(this.stateClass.mobileActive);
    const isDrawerHdgCurrentlyActive = drawerHdg.classList.contains(
      this.stateClass.mobileActive
    );

    // add active class to parent UL if chide is active
    if (isDrawerHdgCurrentlyActive) {
      parentUl?.classList.add(this.stateClass.mobileActive);
      //hide copyright text
      this.toggleCopyrightEl(true);
    } else {
      parentUl?.classList.remove(this.stateClass.mobileActive);
      //Display copyright text
      this.toggleCopyrightEl(false);
    }

    // hide the sibling buttons if drawerHdg has been changed to active
    const parentUlChildren = parentUl?.children;

    if (parentUlChildren) {
      for (let i = 0; i < parentUlChildren.length; i++) {
        const liElement = parentUlChildren[i];
        const sibDrawerHdg = liElement.querySelector(
          this.mobileDrawerHdgSelector
        );

        if (
          isDrawerHdgCurrentlyActive &&
          sibDrawerHdg &&
          !sibDrawerHdg.classList.contains(this.stateClass.mobileActive)
        ) {
          sibDrawerHdg.classList.add(this.stateClass.mobileInactive);
        } else if (sibDrawerHdg) {
          sibDrawerHdg.classList.remove(this.stateClass.mobileInactive);
        }
      }
    }
  }

  /**
   * When clicking on a main tab navigational element show all of the
   * sub elements and make the other main tabs inactive.
   *
   * @param mainTabElement
   */
  mobileShowMainTabSubDrawer(mainTabElement: HTMLElement): void {
    const dataMain = mainTabElement.dataset.main;
    const hasActiveClass = mainTabElement.classList.contains(
      this.stateClass.mobileActive
    );

    // hide utility nav when going into the main nav tab
    this.megaMenuWrapElement
      ?.querySelector(this.utilityNavSelector)
      ?.classList.toggle(this.stateClass.mobileInactive);

    // making sibling elements inactive
    this.mainNavTabList?.forEach((indvMainTabElem) => {
      const matchToDataMain = (indvMainTabElem as HTMLElement).dataset.main;

      if (dataMain && dataMain !== matchToDataMain) {
        indvMainTabElem.classList.remove(this.stateClass.mobileActive);

        if (hasActiveClass) {
          indvMainTabElem.classList.remove(this.stateClass.mobileInactive);
        } else {
          indvMainTabElem.classList.add(this.stateClass.mobileInactive);
        }
      }
    });

    mainTabElement.classList.toggle(this.stateClass.mobileActive);
  }
}

export default MegaMenu;
