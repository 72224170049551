import throttle from 'lodash/throttle';

function navScroll(navElement: HTMLElement): void {
  const initialScrollPos = navElement.parentElement?.parentElement?.offsetTop;

  const navParentElement: HTMLElement | null = navElement.parentElement;

  function stickyOnScroll() {
    const currentScrollPos = window.pageYOffset;
    const navThreshold = 8;

    if (initialScrollPos !== null && currentScrollPos > (initialScrollPos + navThreshold)) {
      navParentElement?.classList.add('jsa-sticky');
    } else {
      navParentElement?.classList.remove('jsa-sticky');
    }
  }

  window.addEventListener('scroll', throttle(stickyOnScroll, 500));
}

export default navScroll;
